import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

interface IProps extends PageProps<any> {}

const NotFoundPage = ({data, location}: IProps) => {
    const siteTitle = data.site.siteMetadata.title;

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="404: Not Found" />
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <a href="/">Back to PragmaticReview homepage</a>
        </Layout>
    );
};

export default NotFoundPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
